<template>
    <component 
        :is="listComponent" 
        :tableType="tableType" 
        :pageConfig="pageConfig" />
</template>

<script>
import ListInit from '../components/ListInit.vue'
import columns from '../mixins/columns.js'
export default {
    mixins: [
        columns
    ],
    props: {
        pageConfig: {
            type: Object,
            default: () => null
        }
    },
    components: {
        ListInit
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        listComponent() {
            if(this.isMobile) {
                return () => import('../components/ListInitMobile.vue')
            } else {
                return () => import('../components/ListInit.vue')
            }
        }
    },
    data() {
        return {
            listProject: true,
            tableType: 'projects'
        }
    }
}
</script>

<style lang="scss" scoped>
    .pj_mobile_padding {
        padding: 15px;
    }
</style>